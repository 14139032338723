import React, { useState } from 'react';
import { axiosPrivate } from "../Components/Api/axios";

const BulkInp = () => {
    const [file, setFile] = useState(null);
    const [notification, setNotification] = useState(''); // Notification state

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileSubmit = async (e) => {
        e.preventDefault();

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axiosPrivate.post('/import/mobile', formData);
                console.log('Upload successful:', response);

                // Show success notification
                setNotification('بارگذاری با موفقیت انجام شد. در حال رفرش صفحه...');

                // Refresh the page after a short delay (e.g., 3 seconds)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } catch (error) {
                console.error('Upload error:', error);

                // Show error notification
                setNotification('بارگذاری با خطا مواجه شد. لطفا مجددا تلاش کنید.');
            }
        } else {
            console.log('No file selected');
        }
    };

    return (
        <div className="lg:w-1/2 mx-auto mt-3">
            <div className="mx-auto text-center">
                <form onSubmit={handleFileSubmit}>
                    <input 
                        type="file" 
                        className="w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" 
                        onChange={handleFileChange} 
                    />
                    <button 
                        type="submit" 
                        className="py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 mt-2 cursor-pointer"
                    >
                        بارگذاری
                    </button>
                </form>
                
                {notification && (
                    <div className="mt-4 text-green-600">
                        {notification}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BulkInp;
