import { useState, useContext } from 'react';
import { Link, useNavigate, Navigate, useLocation } from 'react-router-dom';
import AuthContext from './Context/AuthProvider';
import jbLogo from '../img/jb-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleUser, faBell, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import useAuth from './Hooks/useAuth';
import SideMenu from './SideMenu';
//import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function Header() {

    const { style, setStyle, dark, setDark } = useContext(AuthContext);
    const location = useLocation();

    const changeStyles = () => {
        setStyle(!style);
        console.log(style);
    };

    const [show, setShow] = useState(false);
    let toggleVisibility = (e) => {
        setShow(!show);
    }

    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const logout = async () => {
        setAuth({});
        localStorage.clear();
        navigate('/sign-in');
    }

    const goSignin = async () => {
        <Navigate to="/sign-in" state={{ from: location }} replace />
    }

    const darkModeHandler = () => {
        setDark(!dark);
        document.body.classList.toggle("dark");
    }

    return (
        <div className='main-header bg-light-green dark:bg-dark-blue flex z-50 top-0 fixed w-full border-b-2'>
            <div className='hidden md:flex flex-grow'>
            </div>
            <div onClick={changeStyles} className='flex right-0 top-6 sm:static md:hidden w-1/6 sm:border-l-2 openbtn'>
                <div onClick={changeStyles} className='grid place-content-center text-white text-xl mx-auto'><FontAwesomeIcon icon={faBars} /></div>
            </div>
            <div className='flex absolute right-0 left-0 m-auto md:hidden sm:static grow md:grow-0 justify-center items-center md:-order-1 p-3 md:border-l-2'>
                <img src={jbLogo} alt='logo' width={40} height={48} onClick={changeStyles} />
            </div>
            <div className='flex grow md:grow-0 justify-center items-center md:-order-1 md:border-l-2' style={style ? { position: 'absolute', right: -9999999999 } : { position: 'absolute', right: 'inherit' }}>
                <SideMenu />
            </div>
            <div className='flex justify-center items-center absolute left-0 top-6 sm:static sm:border-r-2 p-x-4 w-40'>
                <button onClick={() => darkModeHandler()}>
                    {dark ? <div className='grid place-content-center text-white font-semibold px-2 text-2xl'><FontAwesomeIcon icon={faSun} /></div>
                        : <div className='grid place-content-center text-white font-semibold px-2 text-2xl'><FontAwesomeIcon icon={faMoon} /></div>}
                </button>
                <div className='relative'>
                    <div className='cursor-pointer select-none flex justify-center items-center px-2' onClick={toggleVisibility}>
                        <div className='grid place-content-center text-white font-semibold text-2xl' ><FontAwesomeIcon icon={faCircleUser} /></div>
                        {/* <div className='text-white font-semibold'>ناحیه کاربری</div> */}
                    </div>
                    {show
                        ?
                        (
                            <nav className='absolute top-full left-1/2 rounded-sm bg-middle-green p-2 z-50'>
                                <ul className="list-none p-0 m-0">
                                    <li className='border-b-2 p-1 text-left cursor-default'>
                                        <div className="font-medium text-white">Sepehr Ahmadi</div>
                                        <div className="text-xs text-white/60 mt-0.5 ">ahmadisepehr95@gmail.com</div>
                                    </li>
                                    <li className='p-1 mt-1 hover:bg-gray-100/20'>
                                        <Link to="/profile" className='flex text-white text-sm'>
                                            <div className='grid place-content-center text-white ml-1'><FontAwesomeIcon icon={faUser} /></div>
                                            <div>پروفایل</div>
                                        </Link>
                                    </li>
                                    {localStorage.getItem('access')
                                        ? <li className='flex border-t-2 p-1 hover:bg-gray-100/20' onClick={logout}>
                                            <div className='grid place-content-center text-white ml-1'><FontAwesomeIcon icon={faRightFromBracket} /></div>
                                            <div className="text-white">خروج</div>
                                        </li>
                                        : <li className='flex border-t-2 p-1 hover:bg-gray-100/20' onClick={goSignin}>
                                            <div className='grid place-content-center text-white ml-1'><FontAwesomeIcon icon={faRightFromBracket} /></div>
                                            <div className="text-white">ورود</div>
                                        </li>
                                    }
                                </ul>
                            </nav>
                        )
                        :
                        ''
                    }
                </div>
                <div className='grid place-content-center text-white font-semibold px-2 text-2xl' ><FontAwesomeIcon icon={faBell} /></div>
                {/* <div className='grid place-content-center text-white'><FontAwesomeIcon icon={faChevronDown} /></div> */}
            </div>
        </div>
    )
}

export default Header
